.wrapper {
    padding: var(--padding-layout);
    padding-bottom: 0;
    margin-top: calc(var(--margin-top-layout) + 40px);
}

.idioms {
    font-size: 2.5rem;
    font-weight: 500;
}

.hr {
    margin: 30px 0 0;
    height: 2px;
    background-color: var(--input);
}

.end {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0 30px;
    height: 100px;
}

.back-to-top {
    cursor: pointer;
}

@media (max-width: 739px) {
    .wrapper {
        padding: 0 10px 20px;
    }
}
