.wrapper {
    padding: var(--padding-layout);
    margin-top: var(--margin-top-layout);
}

a {
    text-decoration: none;
}

.label {
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 48px;
    position: relative;

    &::before {
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 40px;
        background-color: var(--color-primary);
    }
}

.title {
    font-size: 3rem;
    font-weight: 700;
}

.products-wrapper {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    row-gap: 40px;
}

.hide {
    opacity: 0;
    transition: 0.5s;
    transition-delay: 500ms;
    filter: blur(5px);
    position: relative;
    top: 200px;
}

.hide:nth-child(2) {
    transition-delay: 750ms;
}

.hide:nth-child(3) {
    transition-delay: 1000ms;
}

.hide:nth-child(4) {
    transition-delay: 1250ms;
}

.hide:nth-child(5) {
    transition-delay: 1500ms;
}

.hide:nth-child(6) {
    transition-delay: 1750ms;
}

.show {
    opacity: 1;
    filter: blur(0);
    top: 0;
}

.product {
    width: 450px;
    position: relative;
    background-color: var(--background-secondary);
    border-radius: 20px;
    overflow: hidden;

    &-image {
        width: 100%;
        border-radius: 20px 20px 0 0;
    }

    &-btn {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(2px);
        opacity: 0;
        transition: 0.4s;
    }

    &-des {
        padding: 10px 35px;
        text-align: center;
        font-size: 1.1rem;

        &-tech {
            padding: 3px 35px;

            &-title {
                text-decoration: underline;
            }
        }
    }

    &:hover > &-btn {
        top: 0;
        opacity: 1;
    }
}

.btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
}

.product-info {
    padding: 10px 25px 25px;

    &-name {
        font-size: 1.4rem;
        font-weight: 500;
    }

    &-technicals {
        font-size: 1rem;
        font-weight: 400;
        font-style: italic;
        color: var(--color-secondary);
    }
}

.btn-demo,
.btn-github {
    padding: 10px 16px;
    font-size: 1.1rem;
    background-color: var(--color-primary);
    border-radius: 4px;
    color: var(--color-primary);
    outline: none;
    transition: 0.3s;

    &:hover {
        background-color: var(--color-secondary);
        border: 1px solid var(--color-secondary);
        color: var(--background-secondary);
        cursor: pointer;
    }
}

.btn-demo {
    border: 1px solid var(--button-primary);
    background-color: var(--button-primary);
    display: flex;
    gap: 7px;
}
.btn-github {
    border: 1px solid var(--button-secondary);
    background-color: var(--button-secondary);
    display: flex;
    gap: 7px;
}

@media (min-width: 1920px) {
    .product {
        width: 525px;
    }

    .products-wrapper {
        row-gap: 60px;
    }
}

@media (min-width: 1280px) {
    .product {
        width: 500px;
    }

    .products-wrapper {
        row-gap: 60px;
    }
}

@media (max-width: 739px) {
    .wrapper {
        padding: 0 10px 20px;
    }
    .product {
        width: 100%;
    }
}
