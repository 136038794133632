.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background-primary);
    transition: 1s;
    transition-delay: 0.25s;
    opacity: 1;
    z-index: 999;
}

.hidden {
    top: -100%;
    opacity: 0;
}

.circle-progress {
    position: relative;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background: conic-gradient(black 0deg, #eeedef 0deg);
}

@keyframes img {
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

.progress-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.1);
    border-radius: 50%;
    height: 100px;

    animation: img 0.15s linear forwards;
}

@media (max-width: 739px) {
    .progress-img {
        height: 85px;
    }

    .circle-progress {
        height: 100px;
        width: 100px;
    }
}
