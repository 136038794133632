.wrapper {
    padding: var(--padding-layout);
    margin-top: var(--margin-top-layout);
    // position: relative;
    overflow: hidden;

    .swiper-wrapper {
        max-width: 1440px;
        margin: 20px auto;
    }
}

.title {
    font-size: 2.5rem;
    text-align: center;
}

.card-wrapper {
    width: 100%;
    border-radius: 25px;
    background: transparent; // var(--background-secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    margin: 0;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.card-img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    pointer-events: none;
}

.card-name {
    margin-top: 5px;
    font-size: 1.3rem;
}

.hide {
    opacity: 0;
    transition: 0.5s;
    transition-delay: 0.5s;
    filter: blur(5px);
    position: relative;
    right: -200px;
}

.show {
    opacity: 1;
    filter: blur(0);
    right: 0;
}

.hide-title {
    opacity: 0;
    transition: 0.4s;
    filter: blur(5px);
    position: relative;
    left: -100px;
}

.show-title {
    opacity: 1;
    filter: blur(0);
    left: 0;
}

@media (max-width: 739px) {
    .wrapper {
        padding: 0 10px 10px;
    }

    .card-img {
        height: 120px;
        width: 120px;
    }
}
