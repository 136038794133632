@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --color-primary: #ffffff;
    --color-secondary: #dddddd;

    // --main-color: #dddddd;
    // --main-color-hover: #ffffff;

    --background-primary: #121212;
    --background-secondary: #1e1e1e;

    --color-bg-icon: #222222;

    --button-primary: #252525;
    --button-secondary: #686868;

    --input: #303030;

    --input-invalid: #ff8787;

    --padding-layout: 10px 100px;
    --margin-top-layout: 40px;

    --font-size-primary: 3.5rem;
    --font-size-secondary: 2.5rem;
    --info-height: calc(1.5 * var(--font-size-secondary));
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

body {
    background: var(--background-primary);
    color: var(--color-primary);
    min-height: 100vh;
    overflow-x: hidden;
}

/* Custom scroll bar */
::-webkit-scrollbar {
    width: 6px;
    // transition: 2s;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (min-width: 1440px) {
    :root {
        --padding-layout: 10px 150px;

        --font-size-primary: 4.5rem;
        --font-size-secondary: 3rem;
        --info-height: calc(1.5 * var(--font-size-secondary));
    }
}

@media (max-width: 739px) {
    :root {
        --font-size-primary: 2.5rem;
        --font-size-secondary: 1.5rem;
        --info-height: calc(1.5 * var(--font-size-secondary));
    }
}
