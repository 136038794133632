header {
    position: fixed;
    top: -100px; //top: 0;
    left: 0;
    width: 100%;
    height: 88px;
    padding: 20px 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 99;
    background-color: var(--background-secondary);
    opacity: 0;
    transition: 0.5s;

    .menu {
        font-size: 2rem;
        position: absolute;
        top: 15px;
        right: 15px;
        display: none;
        z-index: 999;
        color: var(--color-primary);
    }
}

.logo {
    font-size: 2rem;
    color: var(--color-primary);
    pointer-events: none;
}

.navigation {
    margin-left: 200px;

    ul {
        text-decoration: none;
        list-style-type: none;

        display: flex;

        li {
            font-size: 1rem;

            button {
                text-decoration: none;
                color: var(--color-primary);
                // padding: 6px 3px;
                // border-radius: 20px;
                margin: 0 20px;
                font-weight: 600;
                font-size: 1.1rem;
                position: relative;

                background-color: transparent;
                border: none;
                outline: none;
                // &:hover {
                //     color: var(--color-secondary);
                // }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -5px;
                    background-color: var(--color-primary);
                    width: 0;
                    height: 3px;
                    transition: 0.3s;
                }

                &:hover:after {
                    width: 100%;
                }
            }
        }
    }
}

@keyframes btn-back-to-top-hover {
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}

.back-to-top {
    opacity: 0;
    position: fixed;
    bottom: 20px;
    right: -100px;
    font-size: 1.5rem;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    background-color: var(--button-secondary);
    color: var(--color-primary);
    cursor: pointer;
    transition: 0.5s;
}

.back-to-top:hover {
    background-color: var(--color-primary);
    color: var(--background-primary);
    animation: btn-back-to-top-hover 1s ease-out infinite;
}

.back-to-top-show {
    opacity: 1;
    right: 20px;
}

@media (max-width: 739px) {
    header {
        padding: 10px 25px;
        background-color: transparent;
        height: 68px;

        .mobile-menu {
            margin-left: 0;
        }

        .logo {
            display: none;
        }

        .menu {
            display: flex;
            justify-content: center;
            align-items: center;

            background-color: var(--color-primary);
            color: var(--background-primary);
            width: 60px;
            height: 60px;
            border-radius: 50%;

            .font-aws {
                font-size: 1.65rem;
            }
        }

        .menu-close {
            background-color: transparent;
            color: var(--color-primary);
        }
    }

    .navigation {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(4px);
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -100%;
        transition: all 0.5s ease;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
                margin: 10px 0;
                font-size: 1.5rem;

                button {
                    color: var(--color-primary);
                    font-size: 1.3rem;
                }
            }
        }
    }

    .back-to-top {
        bottom: 15px;
    }

    .back-to-top-show {
        right: 15px;
    }
}

@media (min-width: 1440px) {
    header {
        padding: 20px 150px;
    }
}
