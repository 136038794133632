.wrapper {
    background-color: var(--color-primary);
}

.parallax {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    // ...
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
        // ...
        height: 100vh;
    }
}

.text {
    position: absolute;
    color: var(--color-primary);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.name {
    user-select: none;
    font-size: var(--font-size-primary);
    font-weight: 700;
    line-height: var(--font-size-primary);
    // border-right: 12px solid;

    width: 0;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 1.5s ease-out forwards;
    animation-delay: 1s;
}

// cursor blinking
// @keyframes cursor {
//     50% {
//         border-color: transparent;
//     }
// }

@keyframes typing {
    to {
        width: 100%;
    }
}

.jobs {
    display: flex;
    user-select: none;
    font-size: var(--font-size-secondary);
    font-weight: 700;
}

.hide {
    opacity: 0;
    transition: 0.5s;
    transition-delay: 1.25s;
    filter: blur(5px);
    position: relative;
    top: 200px;
}

.show {
    opacity: 1;
    filter: blur(0);
    top: 0;
}

.sec-texts {
    height: var(--info-height);
    line-height: var(--info-height);
    overflow: hidden;
}

.sec-texts li {
    list-style: none;
    position: relative;
    top: 0;
    animation: slide 10s steps(3) infinite, colorful 5s infinite;
}

@keyframes slide {
    100% {
        top: calc(-3 * var(--info-height));
    }
}

@keyframes colorful {
    100% {
        filter: hue-rotate(360deg);
    }
}

.sec-texts li span {
    color: var(--color-primary);
    padding: 0 2px 0 20px;
    text-shadow: 0 0 6px #0ef;

    position: relative;
}

.sec {
    position: relative;
    background: var(--background-primary);
    // padding: var(--padding-layout);
    // padding-top: 40px;
    color: var(--color-primary);
    overflow: hidden;

    // h2 {
    //     font-size: 3em;
    //     margin-bottom: 10px;
    // }

    // p {
    //     font-size: 1em;
    //     font-weight: 300;
    // }
}

@media (max-width: 739px) {
    .sec-texts li span {
        padding: 0 2px 0 10px;
    }
}
