.wrapper {
    padding: var(--padding-layout);
    margin-top: calc(var(--margin-top-layout) + 150px);
    // overflow: hidden;
    overflow: visible;
}

.box-contact {
    background-color: var(--background-secondary);
    padding: 60px 70px 100px;
    border-radius: 35px;
    position: relative;
}

@keyframes fly {
    to {
        top: -185px;
        right: -75px;
    }
}

.img-witch {
    position: absolute;
    width: 400px;
    top: -169px;
    right: -80px;

    animation: fly 0.8s linear infinite alternate;
}

.img-pumpkin {
    position: absolute;
    width: 380px;

    top: calc(50% - 100px);
}

.contact {
    display: flex;
    flex-wrap: wrap;
}

.label {
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 48px;
    position: relative;

    &::before {
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 40px;
        background-color: var(--color-primary);
    }
}

.title {
    font-size: 3rem;
    font-weight: 700;
}
.idioms {
    color: var(--color-secondary);
    font-size: 1.5rem;
}

.personal-information {
    margin-top: 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: var(--color-secondary);
}

.info {
    font-size: 1.1rem;
    display: flex;

    &-content {
        display: inline;
        margin: auto 0;
        text-decoration: none;
        color: var(--color-primary);
    }
}

.icon {
    display: inline-block;
    font-size: 1.4rem;
    width: 30px;
    text-align: center;
    margin-right: 10px;
}

.get-in-touch {
    padding: 50px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-title {
    font-size: 2.2rem;
    font-weight: 600;
}

.form {
    width: 100%;
}

.contact-form {
    margin-top: 20px;
    width: 100%;
}

.input {
    position: relative;

    &-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-size: 1.2rem;
    }
}

.input-control input,
.input-control textarea {
    width: 100%;

    outline: none;
    border: 2px solid var(--button-secondary);
    border-radius: 12px;
    background-color: var(--button-secondary);
    font-size: 1.1rem;
    padding: 15px 20px;
    color: var(--color-primary);
    transition: 0.3s;
}

.input-invalid input {
    border: 2px solid var(--input-invalid) !important;
}

::placeholder {
    color: var(--color-secondary);
}

.input-control textarea {
    resize: none;
}

.input-control input:focus,
.input-control textarea:focus {
    border: 2px solid var(--color-secondary);
}

.input + .input {
    margin-top: 12px;
}

.error {
    position: absolute;
    top: 50%;
    right: calc(8px + (1.2rem + 20px));
    transform: translateY(-50%);

    font-size: 0.85rem;
    color: var(--input-invalid);
}

.icon-invalid {
    color: var(--input-invalid);
}

.submit {
    opacity: 0;
    margin-left: -50px;

    border: none;
    display: inline-block;
    margin-top: 20px;
    background-color: var(--button-secondary);
    color: var(--color-primary);
    padding: 15px 30px;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: 0.3s;

    &-icon {
        color: var(--color-primary);
    }
}

.submit-input {
    border: none;
    outline: none;
    background: transparent;
    color: var(--color-primary);
    cursor: pointer;
    font-size: 1.2rem;

    transition: 0.3s;
}

.hide {
    opacity: 0;
    transition: 0.5s;
    filter: blur(5px);
    position: relative;
    right: 200px; // -200
}

.hide:nth-child(2) {
    transition-delay: 200ms;
}

.hide:nth-child(3) {
    transition-delay: 400ms;
}

.hide:nth-child(4) {
    transition-delay: 600ms;
}

.hide:nth-child(5) {
    transition-delay: 800ms;
}

.hide:nth-child(6) {
    transition-delay: 1000ms;
}

.show {
    opacity: 1;
    filter: blur(0);
    right: 0;
}

.show-submit {
    opacity: 1;
    margin-left: 0;
}

.submit:hover {
    background-color: var(--color-primary);
    color: var(--background-secondary);

    & > .submit-input {
        color: var(--background-secondary);
    }
}

.submit:hover ~ .submit-icon:hover {
    color: var(--background-secondary);
}

.box-connect {
    display: flex;
    flex-direction: column;

    &-label {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 30px;
    }

    &-container {
        display: flex;
        gap: 20px 25px;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-item {
        border-radius: 15px;
        width: 250px;
        text-align: center;
        padding: 30px 30px;
        background-color: var(--button-secondary);
        display: flex;
        justify-content: center;
        font-size: 1rem;
        line-height: 1rem;
        transition: 0.5s;

        &-icon {
            margin-right: 10px;
        }

        &-label {
            margin: auto 0;
        }
    }

    &-item[data-name='Facebook']:hover {
        background-color: #1773ea;
    }
    &-item[data-name='Github']:hover {
        background-color: #2a3f4b;
    }
    &-item[data-name='Linkedin']:hover {
        background-color: #0073b1;
    }
    &-item[data-name='Download CV']:hover {
        background-color: #65bf44;
    }
    &-item[data-name='Email']:hover {
        background-color: #ec4134;
    }
}

span {
    color: var(--color-primary);
}
.wrapper-popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(14px);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    z-index: 999;
}

@keyframes spining {
    to {
        transform: rotate(360deg);
    }
}

.submit-popup {
    font-size: 3rem;
    animation: spining 1.1s linear infinite;
}

.popup {
    background-color: var(--background-secondary);
    padding: 25px 40px 40px 40px;
    width: 500px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.popup-icon {
    font-size: 5rem;
}

.success-icon {
    color: #36cc95;
}

.btn-success {
    background-color: #36cc95;
    transition: 0.3s;
}

.btn-success:hover {
    background-color: #00b472;
}

.error-icon {
    color: #ff3636;
}

.btn-error {
    background-color: #ff3636;
    transition: 0.3s;
}

.btn-error:hover {
    background-color: #dd2e21;
}

.popup-title {
    margin-top: -15px;
    font-size: 2rem;
}
.popup-message {
    margin-top: 10px;
    font-size: 1.1rem;
}
.popup-btn {
    display: inline-block;
    margin-top: 30px;

    padding: 8px 40px;
    border-radius: 20px;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 1279px) {
    .box-contact {
        padding: 50px 60px 100px;
    }
}

@media (max-width: 959px) {
    .personal-information,
    .get-in-touch {
        width: 100%;
    }

    .img-witch {
        display: none;
    }

    .img-pumpkin {
        display: none;
    }
}

@media (max-width: 739px) {
    .wrapper {
        padding: 0 20px;
    }

    .box-contact {
        padding: 24px 35px 80px;
        border-radius: 25px;
    }

    .get-in-touch {
        padding: 50px 0;
    }

    .popup {
        width: 100%;
        padding: 25px 25px 40px 25px;
    }
}
