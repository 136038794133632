.wrapper {
    width: 100vw;
    height: 130vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    // add
    background-image: url('../../asset/images/bg-about.jpg');
    background-size: 100% 100%;
    background-attachment: fixed;
}

.box-avatar {
    border-radius: 50%;
    width: 320px;
    height: 320px;
    border: 4px solid var(--color-primary);
    position: relative;

    .avatar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 312px;
        border-bottom-left-radius: 156px;
        border-bottom-right-radius: 156px;
        pointer-events: none;
        user-select: none;
    }
}

.box-about {
    width: 56%;
    display: flex;
    flex-direction: column;

    .about-name {
        margin-top: 40px;
        font-size: 2rem;
        font-weight: 500;
    }

    .about-describe {
        margin-top: 2px;
        // text-align: justify;
        color: var(--color-secondary);
    }
}

.about-connect {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 50%;
    width: 320px;
    height: 320px;
    // border: 4px solid red;

    &-item {
        transition: 0.5s;

        &-icon {
            transition: 0.5s;
        }

        &:hover > &-icon {
            transform: scale(1.2);
        }
    }

    &-item[data-name='Facebook']:hover {
        background-color: #1773ea;
    }
    &-item[data-name='Github']:hover {
        background-color: #2a3f4b;
    }
    &-item[data-name='Linkedin']:hover {
        background-color: #0073b1;
    }
    &-item[data-name='Download CV']:hover {
        background-color: #65bf44;
    }
    &-item[data-name='Email']:hover {
        background-color: #ec4134;
    }
}

.about-connect-item {
    width: 80px;
    height: 80px;
    background-color: var(--color-bg-icon);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.social {
    transition: 0.6s;
    transition-delay: 0.25s;

    // &:nth-child(1) {
    //     position: absolute;
    //     top: -10px;
    //     left: -50px;
    // }

    // &:nth-child(2) {
    //     position: absolute;

    //     bottom: 46px;
    //     left: -20px;
    // }

    // &:nth-child(3) {
    //     position: absolute;
    //     bottom: -30px;
    //     right: 60px;
    // }

    // &:nth-child(4) {
    //     position: absolute;
    //     bottom: 46px;
    //     right: -20px;
    // }
}

.hide {
    opacity: 0;

    &:nth-child(1) {
        position: absolute;
        top: -150px;
        left: -170px; //
    }

    &:nth-child(2) {
        position: absolute;
        bottom: -80px; //
        left: -75px;
    }

    &:nth-child(3) {
        position: absolute;
        bottom: -130px;
        right: -100px; //
    }

    &:nth-child(4) {
        position: absolute;
        bottom: 250px; //
        right: -130px;
    }
}

.show {
    opacity: 1;

    &:nth-child(1) {
        position: absolute;
        top: -10px;
        left: -50px;
    }

    &:nth-child(2) {
        position: absolute;

        bottom: 46px;
        left: -20px;
    }

    &:nth-child(3) {
        position: absolute;
        bottom: -30px;
        right: 60px;
    }

    &:nth-child(4) {
        position: absolute;
        bottom: 46px;
        right: -20px;
    }
}

@media (max-width: 739px) {
    .box-about {
        width: 100%;
        padding: 20px;

        .about-name {
            margin-top: 20px;
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
}
